import { LoaderCircle } from "lucide-react";

export default function Loading() {
  return (
    <div className="fixed inset-0 z-50 w-auto h-screen bg-white flex flex-col items-center justify-center">
      <img src="/loading.gif" />
    </div>
  );
  // return (
  //   <div className="fixed inset-0 z-50 w-auto h-screen bg-white flex flex-col items-center border">
  //     <LoaderCircle strokeWidth={3} size={32} className="text-gray-300 animate-spin flex-1" />
  //   </div>
  // );
  // return (
  //   <div className="w-auto h-screen bg-white  flex flex-col items-center ">
  //     <img src="/loading.gif" />
  //   </div>
  // );
}
